export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "拣货单号",
    dataIndex: "picking_order_number",
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: "picking_order_number" },
  },
  {
    title: "客户单号",
    dataIndex: "external_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: "仓库名称",
    dataIndex: "warehouse_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "客户",
    dataIndex: "client_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "操作人",
    dataIndex: "creator_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "单据创建人",
    dataIndex: "order_creator_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    sorter: true,
    width: 180,
    ellipsis: true,
  },
  {
    title: "备注",
    dataIndex: "remark",
    width: 240,
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "action",
    fixed: "right",
    width: 120,
    ellipsis: true,
    scopedSlots: { customRender: "action" },
  },
];
